import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: '集团介绍',
    paragraph: '集团创立最初就秉承”发展企业，回报社会“为宗旨，脚踏实地的去一步一步努力、去实现，持之以恒地去缩短、拉进与客户和市场之间的距离，最终达成一致，提升满意度和企业形象的美誉度；唯如此，企业才能基业长青行稳致远'
  };
//客户至上 团结共进 平等相待 勇于创新 细致执行 是中策集团成立之初的核心价值理念
  return (
    <section
      {...props}
      className={outerClasses}
      id={"section-one"}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  一家历史悠久的多元化企业集团
                  </div>
                <h3 className="mt-0 mb-12">
                  关于中策
                </h3>
                <p className="m-0">
                  经过近三十年的发展，集团依托雄厚的资本、规范现代企业管理制度和各类型专业人才，业已发展成为本土较具实力和知名度的企业集团之一。多年来，集团始终坚持“谋求发展，回报社会”为经营理念，积极投身各项公益事业，赢得了社会各界的广泛认同和普遍赞誉。                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.jpg')}
                  alt="Features split 01"
                  className="has-shadow"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  专业与真诚是我们永恒的追求
                  </div>
                <h3 className="mt-0 mb-12">
                  集团荣誉
                </h3>
                <p className="m-0">
                  作为一个多元化发展的投资公司，中策深切的感受到：只有始终为客户提供高档次、超一流的服务，才能发展。我们相信，只要我们这个具有智慧和充满工作热情的高素质团队，一如既往的坚持中策精神，秉承共同开创事业的传统，与所有关注中策的朋友一起共同努力，我们就一定会拥有更加美好的明天。
                  连续多年荣获“重信誉，创优质服务”先进单位称号，连续多年被授予“守合同重信用先进单位”，连续多年被授予“安徽省著名商标”'
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-02.png')}
                  alt="Features split 02"
                  className="has-shadow"
                  width={528}
                  height={396} />
              </div>
            </div>

            {/*<div className="split-item">*/}
            {/*  <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">*/}
            {/*    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">*/}
            {/*      Lightning fast workflow*/}
            {/*      </div>*/}
            {/*    <h3 className="mt-0 mb-12">*/}
            {/*      Data-driven insights*/}
            {/*      </h3>*/}
            {/*    <p className="m-0">*/}
            {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua — Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.*/}
            {/*      </p>*/}
            {/*  </div>*/}
            {/*  <div className={*/}
            {/*    classNames(*/}
            {/*      'split-item-image center-content-mobile reveal-from-bottom',*/}
            {/*      imageFill && 'split-item-image-fill'*/}
            {/*    )}*/}
            {/*    data-reveal-container=".split-item">*/}
            {/*    <Image*/}
            {/*      src={require('./../../assets/images/features-split-image-03.png')}*/}
            {/*      alt="Features split 03"*/}
            {/*      width={528}*/}
            {/*      height={396} />*/}
            {/*  </div>*/}
            {/*</div>*/}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
