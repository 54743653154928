import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import ParticlesBg from 'particles-bg'
import SmoothScroll from "../elements/SmoothScroll";

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const Hero = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const goToSection = (sectionId) => {
    console.log(sectionId)
    console.log()
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm hero-section">
        <div className={innerClasses}>
          <div className="hero-figure reveal-from-bottom illustration-element-01" data-reveal-value="20px" data-reveal-delay="200">
            <Image
                // className="has-shadow"
                src={require('./../../assets/images/logo-A.png')}
                alt="Hero"
                width={300}
            />
          </div>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="400">
              {/*Landing template for <span className="text-color-primary">startups</span>*/}
              安徽中策投资集团
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="600">
                月月年年，我们都在陈述彼此靠近的理由
                </p>
              <div className="reveal-from-bottom" data-reveal-delay="800">
                <ButtonGroup>
                  {SmoothScroll({to:"section-one", children:<Button color="primary" wideMobile>了解更多</Button>})}
                </ButtonGroup>
              </div>
            </div>
          </div>

          {/*<Modal*/}
          {/*  id="video-modal"*/}
          {/*  show={videoModalActive}*/}
          {/*  handleClose={closeModal}*/}
          {/*  video="https://player.vimeo.com/video/174002812"*/}
          {/*  videoTag="iframe" />*/}
        </div>
        <ParticlesBg type="circle" color={"#03172a"} bg={true} />
      </div>
    </section>
  );
}

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
