import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: '中策集团控股管理企业',
    paragraph: '我们致力于利用我们的专业知识与市场经验着重开发并投资高质量，高回报，高潜力的优质资产，我们勇于创新，坚定不移的完善我们的投资组合',
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className=" mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-01.svg')}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    安徽中策投资有限公司
                    </h4>
                  <p className="m-0 text-sm">
                    是中策集团下属的一家颇具实力的公司。其经营范围广泛，以控股为主；现投资项目涉及房地产开发、营销及营销策划、旅游景区管理、商业贸易等等。中策投资拥有一个由专业投资人才组成的优秀团队，具有资本市场、项目投资、企业管理等方面的丰富经验，现旗下主要投资项目有：中策大厦、燕子河大峡谷旅游管理、淮河路步行街地下商城、租客公寓等                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className=" mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-02.svg')}
                      alt="Features tile icon 02"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    燕子河大峡谷管理有限公司
                    </h4>
                  <p className="m-0 text-sm">
                    国家AAAA级旅游景区、大别山国家地质公园，座落于中国著名将军县金寨县境内，全长约4800米，以"四奇五趣"著称，堪称“人间仙境”。配套设施的四星级仙境宾馆和五星级仙境度假村各项功能齐全，设备高档，可同时接待住宿160余人，并设有山体趣味滑道、玻璃吊桥、高尔夫练习场、网球场、沙滩排球场等各类休闲娱乐设施，具有接待团体会议和个人旅游休闲度假的实力。                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className=" mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-03.svg')}
                      alt="Features tile icon 03"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    中广策广告公司
                    </h4>
                  <p className="m-0 text-sm">
                    隶属于安徽中策集团，集多年的发展运作已成为安徽省具有较高知名度“实力型”的综合级广告公司，荣获国家一级广告公司资质。公司以户外媒体开发、发布为先锋，以营销创意为灵魂，为品牌提供整合营销传播服务；并综合代理电视、电台、报纸等媒体广告。业务范围涉及户外媒体发布、策划、设计、喷绘、写真、印刷、户外广告制作、媒体代理等。                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className=" mb-16">
                    <Image
                      src={require('./../../assets/images/feature-tile-icon-04.svg')}
                      alt="Features tile icon 04"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    安徽中策田园康养有限公司
                    </h4>
                  <p className="m-0 text-sm">
                    公司借助15年旅游开发的积淀，2020年着力打造庐西里康养项目，位于安徽省合肥市肥西县，项目涵盖了短期轻奢民宿，Glamping露营，房车营地，稻田艺术等村落旅游活动，我们致力于为宾客呈现一种集田园、养生、慢节奏为一体的新型生活方式。                    </p>
                </div>
              </div>
            </div>

            {/*<div className="tiles-item reveal-from-bottom" data-reveal-delay="200">*/}
            {/*  <div className="tiles-item-inner">*/}
            {/*    <div className="features-tiles-item-header">*/}
            {/*      <div className="features-tiles-item-image mb-16">*/}
            {/*        <Image*/}
            {/*          src={require('./../../assets/images/feature-tile-icon-05.svg')}*/}
            {/*          alt="Features tile icon 05"*/}
            {/*          width={64}*/}
            {/*          height={64} />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="features-tiles-item-content">*/}
            {/*      <h4 className="mt-0 mb-8">*/}
            {/*        Robust Workflow*/}
            {/*        </h4>*/}
            {/*      <p className="m-0 text-sm">*/}
            {/*        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

            {/*<div className="tiles-item reveal-from-bottom" data-reveal-delay="400">*/}
            {/*  <div className="tiles-item-inner">*/}
            {/*    <div className="features-tiles-item-header">*/}
            {/*      <div className="features-tiles-item-image mb-16">*/}
            {/*        <Image*/}
            {/*          src={require('./../../assets/images/feature-tile-icon-06.svg')}*/}
            {/*          alt="Features tile icon 06"*/}
            {/*          width={64}*/}
            {/*          height={64} />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div className="features-tiles-item-content">*/}
            {/*      <h4 className="mt-0 mb-8">*/}
            {/*        Robust Workflow*/}
            {/*        </h4>*/}
            {/*      <p className="m-0 text-sm">*/}
            {/*        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat.*/}
            {/*        </p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
